import { memo, useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import {
  Typography,
  Stack,
  Box,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { checkedSurvey, Survey } from "api";
import Question from "./Question";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import QuestionEnd from "./QuestionEnd";
import theme from "theme";
import QuestionStart from "./QuestionStart";
import { useAppDispatch } from "stores";
import { appStateMutate } from "stores/modules/app.state/operations";
import version from "version";
import { nanoid } from "@reduxjs/toolkit";
import sendEvent from "modules/sendEvent";

type SurveyProps = {
  survey: Survey;
};

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: "black",
      },
    },
  },
});

const SurveyContent = ({ survey }: SurveyProps) => {
  const [index, setIndex] = useState(0);
  const d = useAppDispatch();
  const sessionId = useRef("");
  if (!sessionId.current) {
    sessionId.current = nanoid();
  }

  const updateUserCheckSurvey = useCallback(() => {
    checkedSurvey(survey.id);
    sendEvent("checkedSurvey", {
      survey_id: survey.id,
    });
  }, [survey.id]);

  const onClose = useCallback(() => {
    if (index === 0) {
      // スタートページで回答しないが押された場合
      updateUserCheckSurvey();
    }
    d(
      appStateMutate((state) => {
        state.informationVersion = version;
        const date = new Date();
        state.informationDate = date.getTime();
      })
    );
  }, [d, updateUserCheckSurvey, index]);

  useEffect(() => {
    if (index === 1) {
      // アンケートが進んだ場合
      updateUserCheckSurvey();
    }
  }, [updateUserCheckSurvey, index]);

  const handleNextUp = useCallback(() => {
    setIndex((draft) => draft + 1);
  }, []);

  useEffect(() => {
    if (survey.id) {
      sendEvent("showSurvey", {
        event_category: "survey",
        event_label: survey.id,
      });
    }
  }, [survey.id]);

  const [t] = useTranslation();

  return (
    <>
      <DialogContent>
        <ThemeProvider theme={lightTheme}>
          {index === 0 && (
            <QuestionStart
              nextUp={handleNextUp}
              startMessage={survey.startMessage}
              cntQuestions={survey.questions.length}
            />
          )}
          {index > 0 && index <= survey.questions.length && (
            <MainContent>
              <Stack flexDirection="row" alignItems="center">
                <Typography gutterBottom>{t("CCFOLIAアンケート")}</Typography>
                <Box flexGrow={1} />
                <Typography>
                  {index}/{survey.questions.length}
                </Typography>
              </Stack>
              <Question
                key={`questions-${index}`}
                surveyId={survey.id}
                question={survey.questions[index - 1]}
                nextUp={handleNextUp}
                sessionId={sessionId.current}
              />
            </MainContent>
          )}
          {index > survey.questions.length && <QuestionEnd onClose={onClose} />}
        </ThemeProvider>
        <Typography variant="body2" display="block" align="center" gutterBottom>
          {t("「{{client}}」からアンケートのお願い", {
            client: survey.client || "unknown",
          })}
        </Typography>
      </DialogContent>
      <DialogActions style={{ background: "rgba(0, 0, 0, 0.4)" }}>
        <Box height="38px" flex={1} />
        {index <= 1 && (
          <Button onClick={onClose} sx={{ color: "text.secondary" }}>
            {t("回答しない")}
          </Button>
        )}
        {index > survey.questions.length && (
          <Button onClick={onClose} color="primary">
            {t("閉じる")}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

const MainContent = styled(Box)`
  position: relative;
  margin: 0 16px 26px;
  padding: 40px 40px 55px 40px;
  height: 366px;
  background: white;
  overflow-y: auto;
  ${theme.breakpoints.down("sm")} {
    height: 420px;
    margin: 0;
    margin-bottom: 16px;
    padding: 20px 16px 20px 16px;
  }
`;

export default memo(SurveyContent);
