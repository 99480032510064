import React, { memo, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import sendEvent from "modules/sendEvent";
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getInformations, KanbanAd, Survey } from "api";
import AdsContent from "./AdsContent";
import SurveyContent from "./SurveyContent";
import { useLocation } from "react-router-dom";
import InformationDialogFooter from "./InformationDialogFooter";

const MAX_DISPLAY_SIZE = 5;

const useFetchItems = (open: boolean) => {
  const [items, setItems] = useState<KanbanAd[]>([]);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [type, setType] = useState<string>("");
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (open) {
      const query = new URLSearchParams(location.search);
      const surveyPreview = query.get("_sp");

      getInformations(surveyPreview)
        .then((content) => {
          setType(content.type);
          if (content.type === "ad") {
            setItems(content.content.slice(0, MAX_DISPLAY_SIZE));
          } else if (content.type === "survey") {
            setSurvey(content.content);
          }
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [setItems, setLoaded, open, location]);
  return [items, loaded, survey, type] as const;
};

const Information = () => {
  const d = useAppDispatch();
  const open = useAppSelector(store.getOpenInformation);
  const [items, loaded, survey, type] = useFetchItems(open);

  useEffect(() => {
    if (open) {
      d(store.loadSupportersCount());
      sendEvent("showInformation");
    }
  }, [open, d]);

  const [t] = useTranslation();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { background: "#222" } }}
    >
      {!loaded && (
        <>
          <DialogContent>
            <SliderContent>
              <LoaderContent>
                <CircularProgress />
              </LoaderContent>
            </SliderContent>
            <Typography
              variant="body2"
              display="block"
              align="center"
              gutterBottom
            >
              {t("読み込み中")}
            </Typography>
          </DialogContent>
          <InformationDialogFooter />
        </>
      )}
      {loaded && type === "ad" && <AdsContent items={items} />}
      {loaded && type === "survey" && survey && (
        <SurveyContent survey={survey} />
      )}
    </Dialog>
  );
};

const SliderContent = styled.div`
  margin: 0 16px 28px;
  .slick-dots li button::before {
    color: #fff;
  }
  .slick-dots li.slick-active button::before {
    color: #fff;
  }
`;
const LoaderContent = styled.div`
  /* border-radius: 4px; */
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  display: block;
  background: #000;
  vertical-align: middle;
  > * {
    margin-top: -20px;
    margin-left: -20px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

export default memo(Information);
