import { memo, useState } from "react";
import { useAppDispatch } from "stores";
import store from "stores/interfaces";
import version from "version";
import sendEvent from "modules/sendEvent";
import { DialogActions, Button, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const InformationDialogFooter = () => {
  const d = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const onClose = () => {
    d(
      store.appStateMutate((state) => {
        state.informationVersion = version;
        const date = new Date();
        if (checked) {
          date.setHours(date.getHours() + 24 * 7);
          sendEvent("checkedInformation");
        }
        state.informationDate = date.getTime();
      })
    );
  };
  const [t] = useTranslation();

  return (
    <DialogActions style={{ background: "rgba(0, 0, 0, 0.4)" }}>
      <Switch
        checked={checked}
        onChange={(_, checked) => setChecked(checked)}
      />
      <Typography variant="caption" color="textSecondary" style={{ flex: 1 }}>
        {t("7日間再表示しない")}
      </Typography>
      <Button onClick={onClose} color="primary">
        {t("閉じる")}
      </Button>
    </DialogActions>
  );
};

export default memo(InformationDialogFooter);
