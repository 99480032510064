import React, { memo, useState, useEffect } from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import sendEvent from "modules/sendEvent";
import theme from "theme";
import Slider from "react-slick";
import { DialogContent, Button, ButtonBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KanbanAd } from "api";
import ReactPlayer from "react-player/youtube";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import InformationDialogFooter from "./InformationDialogFooter";

type AdsProps = {
  items: KanbanAd[];
};

const AdsContent = ({ items }: AdsProps) => {
  const supportersCount = useAppSelector((state) =>
    store.getAppState(state, "supportersCount")
  );
  const [index, setIndex] = useState(0);
  const selectedItems = items[index];

  const [muted, setMuted] = React.useState(true);

  useEffect(() => {
    const handler = () => {
      setMuted(false);
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [setMuted]);
  useEffect(() => {
    if (selectedItems) {
      sendEvent("showAd", {
        event_category: "kanban_ad",
        event_label: selectedItems.campaignId,
      });
    }
  }, [selectedItems]);

  const [t] = useTranslation();

  return (
    <>
      <DialogContent>
        <SliderContent>
          <Slider
            speed={640}
            autoplaySpeed={9600}
            centerPadding="0"
            easing="easeInOut"
            slidesToShow={1}
            slidesToScroll={1}
            afterChange={setIndex}
            centerMode
            dots={items.length < 6}
            infinite={false}
            responsive={[{ breakpoint: 600, settings: { centerMode: false } }]}
          >
            {items.length ? (
              items.map((item, i) => (
                <SliderItemContent key={i}>
                  {item.videoUrl ? (
                    <>
                      <ReactPlayer
                        url={item.videoUrl}
                        playing={index === i}
                        width="100%"
                        height="100%"
                        // controls
                        volume={0.2}
                        muted={muted}
                        className="content"
                        playsinline
                        onPlay={() =>
                          sendEvent("playVideoAd", {
                            event_category: "kanban_ad",
                            event_label: item.campaignId,
                          })
                        }
                        onEnded={() =>
                          sendEvent("endedVideoAd", {
                            event_category: "kanban_ad",
                            event_label: item.campaignId,
                          })
                        }
                        config={{
                          playerVars: {
                            rel: 0,
                          },
                        }}
                      />
                      {item.url && (
                        <Button
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          variant="contained"
                          onClick={() =>
                            sendEvent("clickAd", {
                              event_category: "kanban_ad",
                              event_label: item.campaignId,
                            })
                          }
                        >
                          {t("詳細はこちら")}
                        </Button>
                      )}
                      {muted && (
                        <>
                          <div />
                          <div className="muted">
                            <VolumeOffIcon fontSize="small" />
                            <Typography variant="caption">
                              クリックしてミュート解除
                            </Typography>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <SliderItem
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        sendEvent("clickAd", {
                          event_category: "kanban_ad",
                          event_label: item.campaignId,
                        })
                      }
                      className="content"
                    >
                      <SliderImage src={item.imageUrl} />
                    </SliderItem>
                  )}
                </SliderItemContent>
              ))
            ) : (
              <SliderItem
                href="https://www.pixiv.net/fanbox/creator/34024211/post/718630"
                target="_blank"
                rel="noreferrer"
                onClick={() => sendEvent("clickAd")}
              >
                <SliderImage src="/images/fanbox.png" />
              </SliderItem>
            )}
          </Slider>
        </SliderContent>
        <Typography variant="body2" display="block" align="center" gutterBottom>
          {selectedItems?.kind === "games"
            ? t(
                "ココフォリア上でいろんなゲームを遊ぼう！「{{name}}」の詳細はこちらから。",
                { name: selectedItems?.name || "unknown" }
              )
            : t(
                "ココフォリアは {{people}} 人の開発支援者と 「{{supporter}}」 の提供で運営されています。",
                {
                  people: supportersCount ? supportersCount.value : "****",
                  supporter: selectedItems?.name || "unknown",
                }
              )}
        </Typography>
      </DialogContent>
      <InformationDialogFooter />
    </>
  );
};

const SliderContent = styled.div`
  margin: 0 16px 28px;
  .slick-dots li button::before {
    color: #fff;
  }
  .slick-dots li.slick-active button::before {
    color: #fff;
  }
`;
const SliderItem = styled(ButtonBase)<{
  href?: string;
  target?: string;
  rel?: string;
}>`
  /* border-radius: 4px; */
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  display: block;
  vertical-align: middle;
  &:hover {
    opacity: 0.8;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
const SliderItemContent = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: #000;
  vertical-align: middle;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > .MuiButton-root {
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    bottom: 56px;
    border-radius: 0;
    ${theme.breakpoints.down("sm")} {
      right: 0;
      bottom: 0;
      height: 20px;
      font-size: 10px;
      box-shadow: none;
    }
  }
  > .muted {
    position: absolute;
    top: auto;
    left: 8px;
    right: auto;
    bottom: 8px;
    display: flex;
    align-items: center;
    ${theme.breakpoints.down("sm")} {
      display: none;
    }
    .MuiTypography-root {
      margin-left: 4px;
      line-height: 1;
    }
  }
`;
const SliderImage = styled.img`
  margin: 0 auto;
  width: 100%;
  display: block;
`;

// const AdVisual = styled(ButtonBase)`
//   border-radius: 4px;
//   overflow: hidden;
//   display: block;
//   min-height: 100px;
//   background: #000;
//   &:hover {
//     opacity: 0.8;
//   }
// `;

export default memo(AdsContent);
