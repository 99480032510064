import { Box, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import theme from "theme";

type QuestionStartProps = {
  onClose: () => void;
};

const QuestionEnd = ({ onClose }: QuestionStartProps) => {
  const [t] = useTranslation();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = window.setInterval(() => {
      setProgress((draft) => Math.min(draft + 1, 100));
    }, 45);

    return () => {
      window.clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      window.setTimeout(() => {
        // プログレスバーのアニメーションが終わるのを待ってから閉じる
        onClose();
      }, 500);
    }
  }, [onClose, progress]);

  return (
    <MainContent>
      <SubContent>
        <EndContent textAlign="center">
          <Box>
            <Typography gutterBottom fontWeight="bold" color="textSecondary">
              {t("CCFOLIA アンケート")}
            </Typography>
            <Typography variant={matchSm ? "h5" : "body1"} paragraph>
              <Trans>
                アンケートへのご協力
                <br />
                ありがとうございました！
              </Trans>
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              <Trans>
                引き続き、ココフォリアでのセッションをお楽しみください。
              </Trans>
            </Typography>
            <img
              src="/images/survey-ending.png"
              style={{
                width: "min(180px, 100%)",
                height: "min(180px, 100%)",
                aspectRatio: "1/1",
              }}
              alt=""
            />
          </Box>
        </EndContent>
      </SubContent>
      <LinearProgress variant="determinate" value={progress} />
    </MainContent>
  );
};

const MainContent = styled(Box)`
  position: relative;
  margin: 0 16px 26px;
  ${theme.breakpoints.down("sm")} {
    margin: 0;
    margin-bottom: 16px;
  }
`;

const SubContent = styled(Box)`
  padding: 40px 40px 55px 40px;
  height: 362px;
  background: white;
  ${theme.breakpoints.down("sm")} {
    height: 373px;
    padding: 20px 20px 20px 20px;
  }
`;

const EndContent = styled(Box)`
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%);
  padding: 40px 24px 40px 27px;
  ${theme.breakpoints.down("sm")} {
    margin: auto;
    padding: 2px 0;
  }
`;

export default memo(QuestionEnd);
