import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import theme from "theme";

type QuestionStartProps = {
  cntQuestions: number;
  startMessage: string | null;
  nextUp: () => void;
};

const QuestionStart = ({
  nextUp,
  cntQuestions,
  startMessage,
}: QuestionStartProps) => {
  const [t] = useTranslation();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const totalSeconds = cntQuestions * 30;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const [progress, setProgress] = useState(0);
  const interval = useRef<number | null>(null);
  const timeout = useRef<number | null>(null);

  useEffect(() => {
    interval.current = window.setInterval(() => {
      setProgress((draft) => Math.min(draft + 1, 100));
    }, 95);

    return () => {
      if (interval.current) window.clearInterval(interval.current);
      if (timeout.current) window.clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      timeout.current = window.setTimeout(() => {
        // プログレスバーのアニメーション完了後次のセクションへ
        nextUp();
      }, 500);
    }
  }, [nextUp, progress]);

  const handleNextUp = useCallback(() => {
    if (timeout.current) window.clearTimeout(timeout.current);
    if (interval.current) window.clearInterval(interval.current);
    nextUp();
  }, [nextUp]);

  return (
    <MainContent>
      <SubContent>
        <StartContent textAlign="center">
          <Box>
            <Box marginBottom="24px">
              <Typography gutterBottom fontWeight="bold" color="textSecondary">
                {t("CCFOLIA アンケート")}
              </Typography>
              <Typography
                variant={matchSm ? "h5" : "body1"}
                gutterBottom
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {startMessage ? (
                  <Trans>{startMessage}</Trans>
                ) : (
                  <Trans>
                    サービス改善のため
                    <br />
                    アンケートへのご協力をお願いします
                  </Trans>
                )}
              </Typography>
              <Typography>
                ({t("想定所用時間：")}
                {minutes > 0 && seconds > 0
                  ? t("{{minutes}}分{{seconds}}秒", { minutes, seconds })
                  : minutes > 0
                  ? t("{{minutes}}分", { minutes })
                  : t("{{seconds}}秒", { seconds })}
                )
              </Typography>
            </Box>
            <StartButton
              onClick={handleNextUp}
              size="large"
              variant="outlined"
              fullWidth
            >
              {t("アンケートを開始する")}
            </StartButton>
          </Box>
        </StartContent>
      </SubContent>
      <LinearProgress variant="determinate" value={progress} />
    </MainContent>
  );
};

const MainContent = styled(Box)`
  position: relative;
  margin: 0 16px 26px;
  ${theme.breakpoints.down("sm")} {
    margin: 0;
    margin-bottom: 16px;
  }
`;

const SubContent = styled(Box)`
  padding: 40px 40px 55px 40px;
  height: 362px;
  background: white;
  ${theme.breakpoints.down("sm")} {
    height: 373px;
    padding: 20px 20px 20px 20px;
  }
`;

const StartButton = styled(Button)`
  max-width: 320px;
  height: 60px;
  color: black;
  font-weight: bold;
  border-color: rgba(32, 32, 32, 0.5);
`;

const StartContent = styled(Box)`
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%);
  padding: 40px 24px 40px 27px;
  ${theme.breakpoints.down("sm")} {
    margin: auto;
    padding: 2px 0;
  }
`;

export default memo(QuestionStart);
