import { memo, useState, useCallback, ChangeEvent } from "react";
import { useAppDispatch } from "stores";
import {
  Button,
  Typography,
  Grid,
  TextField,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import {
  sendAnswer,
  sendSurveyAnswerEvent,
} from "stores/modules/app.state/operations";
import theme from "theme";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type question = {
  id: string;
  text: string;
  type: "once" | "multi" | "free";
  choice?: {
    id: string;
    text: string;
  }[];
};

type QuestionProps = {
  surveyId: string;
  question: question;
  nextUp: () => void;
  sessionId: string;
};

const Question = ({ surveyId, question, nextUp, sessionId }: QuestionProps) => {
  const [t] = useTranslation();
  const d = useAppDispatch();
  const [checked, setChecked] = useState<boolean[]>([]);
  const [inputText, setInputText] = useState<string>("");
  const isDisabled =
    question.type === "multi" && checked.filter((check) => check).length === 0;
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const down640 = useMediaQuery(theme.breakpoints.down(640));

  const handleCheck = (index: number) => {
    setChecked((draft) => {
      const newChecked = [...draft];
      newChecked[index] = !newChecked[index];
      return newChecked;
    });
  };

  const handleSendAnswer = useCallback(
    (choice?: string) => {
      let choiceIds: string[] = [];
      let freeText = "";
      switch (question.type) {
        case "once":
          choiceIds.push(choice ?? "");
          break;
        case "multi":
          const choices = question.choice;
          checked.forEach((check, index) => {
            if (check && choices) {
              choiceIds.push(choices[index].id);
            }
          });
          break;
        case "free":
          freeText = inputText.substring(0, 1000);
          break;
      }

      d(
        sendAnswer({
          sessionId,
          surveyId,
          questionId: question.id,
          choiceType: question.type,
          choiceIds,
          freeText,
        })
      );

      d(
        sendSurveyAnswerEvent({
          sessionId,
          surveyId,
          questionId: question.id,
          choiceType: question.type,
          choiceIds,
          freeText,
          isUserInfo: true,
        })
      );

      nextUp();
    },
    [
      d,
      surveyId,
      checked,
      question.type,
      question.id,
      question.choice,
      nextUp,
      sessionId,
      inputText,
    ]
  );

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  }, []);

  const isOver4Column = (question.choice?.length ?? 0) > 4;
  const getFontSize = useCallback(
    (text: string) => {
      if (!downSm) {
        if (down640 && text.length > 24) {
          // 字数が多い場合、breakpointの境でレイアウトが崩れる場合があるため制御
          return "14px";
        }
        return "16px";
      }
      if (question.type === "once") {
        if (
          (isOver4Column && text.length > 15) ||
          (!isOver4Column && text.length > 14)
        ) {
          return "11px";
        }
      } else if (question.type === "multi") {
        if (
          (isOver4Column && text.length > 13) ||
          (!isOver4Column && text.length > 10)
        ) {
          return "11px";
        }
      }
      return "14px";
    },
    [question.type, isOver4Column, downSm, down640]
  );

  return (
    <>
      <Typography variant={downSm ? "body1" : "h5"} paragraph>
        {question.text}
      </Typography>
      <Grid container spacing={1} alignItems="stretch">
        {question.type !== "free" &&
          question.choice?.map((choice, index) => (
            <Grid
              item
              sm={6}
              xs={isOver4Column ? 6 : 12}
              key={`choice-${choice.id}`}
            >
              {question.type === "multi" ? (
                <ChoiceButton
                  onClick={() => handleCheck(index)}
                  fullWidth
                  variant="outlined"
                  size={downSm ? "small" : "large"}
                  type={question.type}
                  endIcon={
                    <Checkbox
                      disableRipple
                      checked={checked[index] || false}
                      color="primary"
                      sx={{
                        padding: "8px",
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  }
                >
                  <ChoiceText size={getFontSize(choice.text)}>
                    {choice.text}
                  </ChoiceText>
                </ChoiceButton>
              ) : (
                <ChoiceButton
                  onClick={() => handleSendAnswer(choice.id)}
                  fullWidth
                  variant="outlined"
                  type={question.type}
                  size={downSm ? "small" : "large"}
                >
                  <ChoiceText size={getFontSize(choice.text)}>
                    {choice.text}
                  </ChoiceText>
                </ChoiceButton>
              )}
            </Grid>
          ))}
        {question.type === "free" && (
          <Grid item xs={12}>
            <TextField
              hiddenLabel
              variant="filled"
              onChange={handleOnChange}
              fullWidth
              multiline
              placeholder={t("回答を入力")}
              rows={downSm ? 3 : undefined}
              maxRows={downSm ? 3 : 5}
              helperText={`${inputText.length}/1000`}
              inputProps={{
                maxlength: 1000,
              }}
            />
          </Grid>
        )}
        {question.type !== "once" && (
          <Grid item sm={6} xs={12}>
            <ChoiceButton
              variant="contained"
              fullWidth
              size={downSm ? "small" : "large"}
              onClick={() => handleSendAnswer()}
              color="primary"
              disabled={isDisabled}
            >
              {t("回答を送信")}
            </ChoiceButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ChoiceButton = styled(Button)<{ type: string }>`
  height: 60px;
  line-height: 140%;
  justify-content: space-between;
  text-align: left;
  border-color: rgba(32, 32, 32, 0.5);
  text-transform: none;
  overflow-wrap: anywhere;
  ${(props) => {
    if (props.type === "once") {
      return `
        padding-left: 16px;
        padding-right: 16px;
      `;
    }
    if (props.type === "multi") {
      return `
        padding-left: 16px;
        padding-right: 8px;
      `;
    }
  }};

  .MuiButton-endIcon {
    margin: 0;
  }

  ${theme.breakpoints.down("sm")} {
    height: 56px;
    line-height: 120%;
    ${(props) => {
      if (props.type === "once") {
        return `
          padding-left: 8px;
          padding-right: 8px;
        `;
      }
      if (props.type === "multi") {
        return `
          padding-left: 6px;
          padding-right: 0px;
        `;
      }
    }};
  }
`;

const ChoiceText = styled(Typography)<{ size: string }>`
  line-height: 140%;
  font-size: ${(props) => props.size};

  ${theme.breakpoints.down("sm")} {
    line-height: 120%;
  }
`;

export default memo(Question);
